<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>

          <b-button
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="ExportAllToFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>
          <!-- bouton export end -->
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-2 row">
            <!-- search start -->

            <!-- select importateur end -->
          </div>

          <div class="my-2 mx-1 row">
            <!-- search start -->
            <div class="col-lg-3 col-md-6 mb-2 mb-lg-0">
              <b-form-input
                id="filter-input"
                v-model="valueSearch"
                type="search"
                placeholder="Recherche"
                @input="filterValue()"
              >
              </b-form-input>
            </div>
            <!-- select importateur start -->
            <div class="col-lg-3 col-md-6 mb-2 mb-lg-0">
            <b-form-select
              v-model="selectedAssociation"
              :options="associations"
              value-field="name"
							text-field="name"
              @input="filterValue()"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                  >-- Associés/Acheteurs --</b-form-select-option
                >
              </template>
            </b-form-select>
            <label>Associés/Acheteurs</label>
            </div>
            <div class=" mb-2 mb-lg-0">
              <b-input-group prepend="Total des recrouvement à faire">
                <b-form-input
                  v-model="recouvrementsTotal"
                  type="text"
                  disabled
                  class="col-lg-3 col-md-6"
                  placeholder="Solde"
                >
                </b-form-input>
              </b-input-group>
            </div>
          </div>

          <!-- table start -->
          <b-table
            v-model="excelRecouvrement"
            id="my-table"
            responsive
            :fields="headers"
            :items="recouvrements"
            :per-page="perPage"
            @filtered="onFiltered"
            :current-page="currentPage"
            :filter="search"
            show-empty
            class="border-bottom"
            :filter-function="filterFunction"
          >
            <template #cell(vehicule)="data">
              {{ data.item.vehicule.marque }} - Année
              {{ data.item.vehicule.annee }}
            </template>
            <template #cell(total_paye)="data">
              {{
                data.item.total_paye
                  ? $thousandSeparator(data.item.total_paye)
                  : "-----"
              }}
            </template>
            <template #cell(montant_vente)="data">
              {{
                data.item.montant_vente
                  ? $thousandSeparator(data.item.montant_vente)
                  : "-----"
              }}
            </template>
            <template #cell(reste_a_paye)="data">
              {{
                data.item.reste_a_paye
                  ? $thousandSeparator(data.item.reste_a_paye)
                  : "-----"
              }}
            </template>

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div>
                <b-button
                  v-if="$hasFonction(user, 'Vendre véhicule / Recouvrement')"
                  size="sm"
                  variant="flat-success"
                  class="btn-icon"
                  @click="recouvrirDialog(data.item)"
                >
                  <feather-icon icon="RepeatIcon" />
                </b-button>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-1" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
                        <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                        </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal edit start  -->
        <b-modal
          size="lg"
          id="ded-modal"
          v-model="dialogDedomager"
          title="Effectuer un recouvrement"
          @hidden="resetDialog"
          hide-footer
          :ok-disabled="isOperating"
          centered
        >
          <b-form
            class="mt-1"
            ref="editedItem"
            @submit.stop.prevent="generateData"
          >
            <b-row>
              <b-col>
                <label for="">Reste à payer Initial</label>
                <b-button block variant="secondary">
                  {{ $thousandSeparator(editedItem.reste_a_paye) }}
                </b-button>
              </b-col>
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  name="montant"
                  rules="required"
                >
                  <label for="">Montant à payer</label>
                  <cleave
                    id="montant"
                    placeholder="0"
                    v-model="editedItem.prix_vente"
                    :state="errors.length > 0 ? false : null"
                    class="form-control"
                    :raw="false"
                    :options="numberFormat"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col>
                <label for="">Reste à payer final</label>
                <b-button block variant="secondary">
                  {{ $thousandSeparator(recouvrirRapFinal) }}
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="checkDateLimiteRecouvrement">
              <b-col>
                <div class="demo-inline-spacing">
                  <b-form-radio v-model="mode_solde" value="solderauplustard">
                    Solde au plus tard
                  </b-form-radio>
                  <b-form-radio v-model="mode_solde" value="solderavantsortie">
                    Solde av. sortie
                  </b-form-radio>
                </div>
              </b-col>
              <b-col class="mt-1" v-if="existDateLimite">
                <b-form-datepicker
                  v-model="editedItem.datelimite"
                  placeholder="Solder au plus tard le"
                  labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
				  labelNoDateSelected="Aucune date sélectionnée"
				  labelResetButton="Annuler"
                  reset-button
                ></b-form-datepicker>
              </b-col>
            </b-row>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="dialogDedomager = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButton"
                variant="primary"
                class="mt-2"
                @click="generateData"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
        </b-modal>
        <!-- modal edit end -->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
import { ModelListSelect } from "vue-search-select";
// import { FormWizard, TabContent } from 'vue-form-wizard'
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BBadge,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BFormRadio,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconArrowClockwise,
  BIconCircleFill,
  BIconCartPlus,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    Cleave,
    ModelListSelect,
    FormWizard,
    TabContent,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BIconArrowClockwise,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BFormRadio,
    BCardText,
    BTable,
    BBadge,
    BIconCartPlus,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      etatButton: true,
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        delimiter: " ",
      },
      dateFormatOptions: { day: "numeric", month: "long", year: "numeric" },
      dialogDedomager: false,
      search: "",
      mode_solde: "solderauplustard",
      recouvrements: [],
      selectedAssociation: "",
      excelRecouvrement: [],
      associes: [],
      editedItem: {},
      valueStart: null,
      valueSearch: null,
      valueEnd: null,
      errorValueStart: "",
      hidden: true,
      hiddenEmpty: true,
      isOperating: false,
      hiddenLoader: false,
      perPage: 10,
      allRows: 0,
      currentPage: 1,
      //filterValue: "",
      pageOptions: [],
      headers: [
        { label: "Association", key: "association" },
        { label: "Clients", key: "client.name" },
        { label: "Véhicules", key: "vehicule" },
        { label: "Chassis", key: "vehicule.numero_chassis" },
        { label: "Montant Vente", key: "montant_vente" },
        { label: "Total payé", key: "total_paye" },
        { label: "Reste à payer", key: "reste_a_paye" },
        { label: "Actions", align: "start", key: "actions" },
      ],
      associations: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),

    totalRows() {
      // Set the initial number of items
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },
    existDateLimite() {
      if (this.mode_solde == "solderauplustard") return true;
      return false;
    },

    recouvrirRapFinal() {
      let num1 = this.editedItem.reste_a_paye
        ? this.editedItem.reste_a_paye
        : 0;
      let num2 = this.editedItem.prix_vente
        ? this.$thousandFormater(this.editedItem.prix_vente)
        : 0;

      return num1 - num2 > 0 ? num1 - num2 : 0;
    },
    checkDateLimiteRecouvrement() {
      if (parseInt(this.recouvrirRapFinal) > 0) return true;
      else return false;
    },

    recouvrementsTotal() {
      let rec = 0;
        this.recouvrements.forEach((element) => {
          rec += element.reste_a_paye;
        });
        return this.$thousandSeparator(rec);
    },
  },
  created() {
    this.associesData();
    this.initialize();
    this.getAssociationList();
  },
  methods: {
    etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
        totalRows()
      },

    generateData(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;

      if (this.editedItem.invoice_type == "Facture normalisée") {
        let today = new Date();
        var date =
          today.getDate() +
          "/" +
          (today.getMonth() + 1) +
          "/" +
          today.getFullYear();
        let lastInvoice = JSON.parse(this.editedItem.mcf_response);
        let newifu
        if(lastInvoice.customerIfu===null){
          newifu="0000000000000"
        }else if(lastInvoice.customerIfu.length< 13){
          newifu="0000000000000"
        }
        else {
          newifu=lastInvoice.customerIfu
        }
        const data = {
          type: lastInvoice.type,
          orignal: "000",
          date: date,
          reference: "-------",
          discount: 0,
          company: {
            name: lastInvoice.companyName,
            address: lastInvoice.companyAdress,
            contact: lastInvoice.companyContact,
            ifu: lastInvoice.ifu,
          },
          operator: {
            id: lastInvoice.operatorId,
            name: lastInvoice.operatorName,
          },
          customer: {
            name: lastInvoice.customerName,
            ifu: newifu,
            amount: this.$thousandFormater(this.editedItem.prix_vente),
            change: 0,
          },
          vat: {
            type: "A",
            value: 0,
          },
          tax: 0,
          aib: "",
          products: [
            {
              description: lastInvoice.items[0].name,
              price: this.$thousandFormater(this.editedItem.prix_vente),
              quantity: 1,
            },
          ],
        };

        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer bXNmOnVzZXI=");
        myHeaders.append("content-type", "application/json");
        myHeaders.append("content", JSON.stringify(data));
        var myInit = {
          method: "POST",
          headers: myHeaders,
          mode: "cors",
          cache: "default",
        };

        fetch("http://127.0.0.1:3440", myInit)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            if (!res.success) {
              this.isOperating = false;
              this.standardizedInvoiceGenerated = false;
              if (res.result == "No device connected")
                res.result = "Aucun appareil connecté";
              else if (res.result == "Device not compatible")
                res.result = "Appareil MECEF non compatible";
              else res.result = "Veuillez lancer le serveur MECEF";
              this.showToast(res.result, "danger");
            } else {
              this.editedItem.mcf_request_content = res.result;
              this.editedItem.mcf_response = res.result;
              this.recouvrer();
              this.standardizedInvoiceGenerated = true;
            }
          })
          .catch((error) => {
            this.standardizedInvoiceGenerated = false;
            this.showToast(error, false);
          });
        this.$nextTick(() => {
          this.snack = false;
        });
      } else {
        this.recouvrer();
      }
    },

    async associesData() {
      await this.$http.get("/associes").then((response) => {
        this.associes.push(response.data.data);
        console.log(this.associes.length)

        this.associes.forEach((element) => {
          element.associe_name = element.name;
        });
      });
    },

    async initialize() {
      await this.$http.get("/recouvrements").then((response) => {
        if (response.status) {
          this.hiddenLoader = true;
        }

        this.recouvrements = response.data.data;

        if (this.recouvrements.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
        
        this.allRows = this.recouvrements.length
      });
    },
    getAssociationList() {
      this.$http
        .get("/associations")
        .then((res) => {
          this.associations = res.data.data;
        })
        .catch((e) => {
          console.log("erreur associationList:", e.response.data.message);
        });
    },
    filterValue(val) {
        this.search = ' '
    },
    filterFunction(row) {
      
      console.log(row.association)
        if (this.selectedAssociation != null && this.selectedAssociation != '' && this.selectedAssociation != row.association) {
          return false
        }  else if(this.valueSearch != null && row.client.name.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 
                && row.vehicule.numero_chassis.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 
                && row.montant_vente.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 
                && row.total_paye.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 
                && row.association.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 
                && row.reste_a_paye.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 ){
           return false
        }  else {
          return true;
        } 
    },
    recouvrirDialog(item) {
      item.client = item.client.id;
      item.vehicule_id = item.vehicule.id;
      item.id = item.vehicule.id;
      if (item.signataire) item.signataire = item.signataire.id;
      item.avance_percue = 0;
      delete item.prix_vente;
      item.net_a_paye = 0;
      item.remise = 0;

      this.editedItem = item;

      this.dialogDedomager = true;
    },

    async recouvrer() {
      this.editedItem.montant_paye = this.$thousandFormater(
        this.editedItem.prix_vente
      );

      this.etatButton = false;

      this.$http
        .post("/factures", this.editedItem)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success == false) {
            this.etatButton = true;
            this.showToast(result.data.message, "danger");
          } else {
            this.etatButton = true;
            this.showToast("Opération effectuée", "success");

            let id =
              result.data.data.facture[result.data.data.facture.length - 1].id;

            let routeData = this.$router.resolve({
              name: "facture-show",
              params: { id: id },
            });

            window.open(routeData.href, "_blank");

            this.initialize();
          }
          this.resetDialog();
        })
        .catch((err) => {
          this.etatButton = true;
          this.isOperating = false;
          this.showToast(err.response.data.message, "danger");
        });
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    resetDialog() {
      this.editedItem = {};
      this.dialogDedomager = false;
    },

    convert(date) {
      if (date) {
        date = new Date(date);
      }
      return this.reformatDate(date);
    },

    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return this.reformatDate(date);
    },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = date.getMonth() + 1;
        let jour = date.getDate();
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },

    dedomager(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.$http
        .post("/recouvrements", this.editedItem)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success == true) {
            this.showToast("Opération éffectuée", "success");
            this.resetDialog();
            this.initialize();
          } else {
            let error = result.data.message;
            if (error.transaction_error) {
              this.showToast(error.transaction_error[0], "danger");
            } else {
              this.showToast(
                error[Object.keys(transaction_error)[0]][0],
                "danger"
              );
            }
          }
        })
        .catch((err) => {
          this.isOperating = false;
          let error = err.response.data.message;
          if (error.transaction_error) {
            this.showToast(error.transaction_error[0], "danger");
          } else {
            this.showToast(err.response.data.message, "danger");
          }
        });
    },

	ExportFomatExcel() {
		let { json2excel, excel2json } = require("js2excel");
		// let headers = this.headers
		let data = [];
		let obj = {};

		this.excelRecouvrement.forEach((element) => {
			obj = {
				"ASSOCIATION": element.association,
				"CLIENTS": element.client.name,
				"VEHICULES": element.vehicule,
				"CHASSIS": element.vehicule.numero_chassis,
				"MONTANT VENTE": element.montant_vente ? element.montant_vente : 0,
				"TOTAL PAYE": element.total_paye ? element.total_paye : 0,
				"RESTE À PAYER": element.benefice ? element.benefice : 0,
			};

			data.push(obj);
		});

		if (data) {
			try {
				json2excel({
					// headers,
					data,
					name: "FlashCar - Liste des Recouvrer",
					formateDate: "yyyy/mm/dd",
				});
			} catch (e) {
				console.log(e);
			}
		}
	},

	ExportAllToFomatExcel() {
		let { json2excel, excel2json } = require("js2excel");
		// let headers = this.headers
		let data = [];
		let obj = {};

		this.recouvrements.forEach((element) => {
			obj = {
				"ASSOCIATION": element.association,
				"CLIENTS": element.client.name,
				"VEHICULES": element.vehicule,
				"CHASSIS": element.vehicule.numero_chassis,
				"MONTANT VENTE": element.montant_vente ? element.montant_vente : 0,
				"TOTAL PAYE": element.total_paye ? element.total_paye : 0,
				"RESTE À PAYER": element.benefice ? element.benefice : 0,
			};

			data.push(obj);
		});

		if (data) {
			try {
				json2excel({
					// headers,
					data,
					name: "FlashCar - Liste des Recouvrer",
					formateDate: "yyyy/mm/dd",
				});
			} catch (e) {
				console.log(e);
			}
		}
	},

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

.vue-form-wizard {
  box-shadow: 0px 0px;
  margin-bottom: 0px !important;
}

.wizard-card-footer {
  height: 20px;
}

.wizard-footer-left .wizard-btn {
  //   color: #27295c !important;
  //   border: 1px solid #27295c !important;
  //   background-color: white !important;
  padding-left: 1px !important;
  padding-top: 1px !important;
  padding-right: 15px !important;
  padding-bottom: 1px !important;
}

.wizard-footer-right .wizard-btn {
  color: #27295c !important;
  border: 1px solid #27295c !important;
  background-color: white !important;
  padding-left: 15px !important;
  padding-top: 1px !important;
  padding-right: 0px !important;
  padding-bottom: 1px !important;
}

.wizard-card-footer {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .annuler {
    margin-bottom: 5px;
  }

  .blockSearch {
    width: 350px !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {
  /* .annuler {
			margin-bottom: 5px;
		} */

  .form {
    margin-left: 10%;
  }

  .formRow .form-group {
    width: 500px;
  }

  .blockForm {
    margin-left: 0px !important;
  }

  .blockSearch {
    width: 80% !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }

  .BlockbButton {
    padding-right: 0px !important;
    margin-right: 40px !important;
  }
}

@media screen and (max-width: 1000px) {
  #solde-input {
    margin-left: 20px !important;
  }

  .form {
    margin-left: 12%;
  }

  .fonction {
    width: 520px;
  }

  .BlockbButton {
    padding-right: 130px !important;
  }
}
</style>